import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Certificate.scss';
import certificateTemplate from '../../../Assets/June Workshop certificate.png';
import bannerImage from '../../../Assets/AcademyInternship/CourseBanner.png'; 
import { toPng } from 'html-to-image';
import { jsPDF } from 'jspdf';
import { FaShareSquare } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLink } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { Toast } from 'primereact/toast';
import emailjs from 'emailjs-com';
import Header from '../../Home/Header/Header';
import Footer from '../../Home/Footer/Footer';

const Certificate = () => {
    const ref = useRef(null);
    const toast = useRef(null);
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [workshopName, setWorkshopName] = useState('');
    const [location, setLocation] = useState('');
    const [workshopDate, setWorkshopDate] = useState('');
    const [emails, setEmails] = useState('');
    const [showEmailPrompt, setShowEmailPrompt] = useState(false);
    const [emailFormat, setEmailFormat] = useState('image');

    const [isImageDropdownOpen, setIsImageDropdownOpen] = useState(false);
    const [isPdfDropdownOpen, setIsPdfDropdownOpen] = useState(false);
    const dropdownImageRef = useRef(null);
    const dropdownPdfRef = useRef(null);

    const locationHook = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(locationHook.search);
        const nameFromURL = params.get('name');
        const workshopFromURL = params.get('workshop');
        const locationFromURL = params.get('location');
        const workshopDateFromURL = params.get('workshopDate');
        if (nameFromURL) setName(nameFromURL);
        if (workshopFromURL) setWorkshopName(workshopFromURL);
        if (locationFromURL) setLocation(locationFromURL);
        if (workshopDateFromURL) setWorkshopDate(workshopDateFromURL);
    }, [locationHook.search]);

    // Define handleBackClick function here
    const handleBackClick = () => {
        navigate('/Studentprofile'); // Replace '/Studentprofile' with your desired route
    };

    const generateImage = useCallback(async () => {
        const node = ref.current;
        const dataUrl = await toPng(node, {
            cacheBust: true,
            pixelRatio: 3,
            width: node.scrollWidth,
            height: node.scrollHeight,
            style: {
                transform: 'scale(1)',
                transformOrigin: 'top left',
                width: `${node.scrollWidth}px`,
                height: `${node.scrollHeight}px`,
            }
        });
        return dataUrl;
    }, []);

    const generatePDF = useCallback(async () => {
        const dataUrl = await generateImage();
        const pdf = new jsPDF('landscape', 'pt', 'a4');
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
        const pdfBlob = pdf.output('blob');
        const url = URL.createObjectURL(pdfBlob);
        return url;
    }, [generateImage]);

    const sendEmail = async () => {
        const fileUrl = emailFormat === 'image' ? await generateImage() : await generatePDF();
        const emailArray = emails.split(',').map(email => email.trim());

        for (let i = 0; i < emailArray.length; i++) {
            const emailParams = {
                to_email: emailArray[i],
                name: name,
                workshop_name: workshopName,
                certificate_url: fileUrl,
                linkedin_url: 'https://www.linkedin.com/company/your-linkedin-url',
                file_name: emailFormat === 'image' ? 'certificate.png' : 'certificate.pdf'
            };

            emailjs.send('service_sv5uthk', 'template_gpg652y', emailParams, 'o5DCZGQ7urc4P1HLC')
                .then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: `Certificate sent to ${emailArray[i]}` });
                }, (error) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: `Failed to send email to ${emailArray[i]}.` });
                });
        }

        setShowEmailPrompt(false);
        setEmails('');
    };

    const onButtonClick = useCallback(() => {
        generateImage()
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = 'certificate.png';
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    }, [generateImage]);

    const handleShare = async (option, format) => {
        let fileUrl;
        if (format === 'image') {
            fileUrl = await generateImage();
        } else if (format === 'pdf') {
            fileUrl = await generatePDF();
        }

        switch (option) {
            case 'WhatsApp':
                window.open(`https://wa.me/?text=Check out this certificate: ${fileUrl}`, '_blank');
                break;
            case 'Facebook':
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${fileUrl}`, '_blank');
                break;
            case 'X':
                window.open(`https://x.com/intent/tweet?url=${fileUrl}&text=Check out this certificate`, '_blank');
                break;
            case 'LinkedIn':
                window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${fileUrl}`, '_blank');
                break;
            default:
                break;
        }

        setIsImageDropdownOpen(false);
        setIsPdfDropdownOpen(false);
    };

    return (
        <div>
            <Header />

            {/* Banner Section */}
            <div className="certificate-banner-section">
                <img src={bannerImage} alt="Certificates Banner" className="certificate-banner-image" />
                <h1 className="banner-text">Certificates</h1>
            </div>

            
            <button className="back-button" onClick={handleBackClick}>← Back</button>

            <div className="certificate-page">
                <Toast ref={toast} />

                
                <div className="certificate-content">
                    <div className="certificate-image" ref={ref}>
                        <img src={certificateTemplate} alt="Certificate Template" />
                       
                        <div className="certificate-student-name">{name}</div>
                    </div>
                    <div className="certificate-details">
                        <h2>Certificate owner</h2>
                        <p><strong>Student Name:</strong> {name}</p>
                        <p><strong>Workshop:</strong> {workshopName}</p>
                        <p><strong>Place:</strong> {location}</p>
                        <p><strong>Date:</strong> {workshopDate}</p>

                        <div className="actions">
                            <button onClick={onButtonClick} className="download-button">Download</button>

                            {/* Share buttons */}
                            <button onClick={() => setIsImageDropdownOpen(!isImageDropdownOpen)} className="share-button"><FaShareSquare /> Share as Image</button>
                            {isImageDropdownOpen && (
                                <ul ref={dropdownImageRef} className="dropdown-menu">
                                    <li onClick={() => handleShare('WhatsApp', 'image')}>
                                        <IoLogoWhatsapp className='social-icon' style={{ color: '#25D366' }} /> WhatsApp
                                    </li>
                                    <li onClick={() => handleShare('Facebook', 'image')}>
                                        <FaFacebook className='social-icon' style={{ color: '#316FF6' }} /> Facebook
                                    </li>
                                    <li onClick={() => handleShare('X', 'image')}>
                                        <FaSquareXTwitter className='social-icon' /> X
                                    </li>
                                    <li onClick={() => handleShare('LinkedIn', 'image')}>
                                        <IoLogoLinkedin className='social-icon' style={{ color: '#0077B5' }} /> LinkedIn
                                    </li>
                                    <li onClick={() => { setEmailFormat('image'); setShowEmailPrompt(true); }}>
                                        <FaLink className='social-icon' /> Send as Image to Email
                                    </li>
                                </ul>
                            )}

                            <button onClick={() => setIsPdfDropdownOpen(!isPdfDropdownOpen)} className="share-button"><FaShareSquare /> Share as PDF</button>
                            {isPdfDropdownOpen && (
                                <ul ref={dropdownPdfRef} className="dropdown-menu">
                                    <li onClick={() => handleShare('WhatsApp', 'pdf')}>
                                        <IoLogoWhatsapp className='social-icon' style={{ color: '#25D366' }} /> WhatsApp
                                    </li>
                                    <li onClick={() => handleShare('Facebook', 'pdf')}>
                                        <FaFacebook className='social-icon' style={{ color: '#316FF6' }} /> Facebook
                                    </li>
                                    <li onClick={() => handleShare('X', 'pdf')}>
                                        <FaSquareXTwitter className='social-icon' /> X
                                    </li>
                                    <li onClick={() => handleShare('LinkedIn', 'pdf')}>
                                        <IoLogoLinkedin className='social-icon' style={{ color: '#0077B5' }} /> LinkedIn
                                    </li>
                                    <li onClick={() => { setEmailFormat('pdf'); setShowEmailPrompt(true); }}>
                                        <FaLink className='social-icon' /> Send as PDF to Email
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {showEmailPrompt && (
                <div className="email-prompt">
                    <div className="email-prompt-content">
                        <h3>Send Certificate via Email</h3>
                        <input
                            type="text"
                            placeholder="Enter recipient's emails"
                            value={emails}
                            onChange={(e) => setEmails(e.target.value)}
                            required
                        />
                        <button onClick={sendEmail} className="send-email-button">Send</button>
                        <button onClick={() => setShowEmailPrompt(false)} className="cancel-button">Cancel</button>
                    </div>
                </div>
            )}

            <Footer />
        </div>
    );
};

export default Certificate;

import { Button } from "primereact/button";
import React from "react";
import bannerImg from "../../../Assets/Academy/banner-workshop guide.png";

const WorkshopGuideBanner = () => {
    return (
        <div className='s2l-banner-section mx-2'>
            <div className='banner-content'>
                <h3 className="mb-0">S2L Academy presents</h3>
                <h2><span className="startup-text">Workshop study guide</span><br /></h2>
                <p>
                    Welcome to your personalized workshop guide! This is your go-to hub for all
                    things related to the course. You'll find your weekly lessons, note-taking tools,
                    a research chatbot, and ways to track your progress.
                </p>
                <p>
                    Ready to get started on your entrepreneurial journey?
                </p>
                <div className="flex gap-4">
                    <Button className='button-style-square-line'><a href='/login' rel="noopener noreferrer">Login</a></Button>

                    <Button className='purple-bg'><a href='/newclientform' rel="noopener noreferrer">Contact Us</a></Button>

                </div>
            </div>


            <div className='banner-image'>
                <img src={bannerImg} alt='People working around a table' />
            </div>
        </div>
    )
}

export default WorkshopGuideBanner;
import React from 'react';
import './Startup2launchmission.scss';
import InnovationIcon from '../../../Assets/AboutUs/ourvalues/Innovation.svg';
import CollaborationIcon from '../../../Assets/AboutUs/ourvalues/Collaboration.svg';
import IntegrityIcon from '../../../Assets/AboutUs/ourvalues/Integrity.svg';
import ExcellenceIcon from '../../../Assets/AboutUs/ourvalues/Excellence.svg';

const Startup2launchMission = () => {
  return (
    <div className="values-section">
    <h2 className="values-header">Our values</h2>
    <div className="value-boxes-container">
      <div className="value-box">
        <img src={InnovationIcon} alt="Innovation" className="value-icon" />
        <div className="value-content">
          <h2 className="value-title">Innovation</h2>
          <p className="value-description">Embracing creativity and forward-thinking to turn unique ideas into practical solutions.</p>
        </div>
      </div>
      <div className="value-box">
        <img src={IntegrityIcon} alt="Integrity" className="value-icon" />
        <div className="value-content">
          <h2 className="value-title">Integrity</h2>
          <p className="value-description">Upholding transparency, honesty, and ethical practices in all aspects of our business.</p>
        </div>
      </div>
      <div className="value-box">
        <img src={CollaborationIcon} alt="Collaboration" className="value-icon" />
        <div className="value-content">
          <h2 className="value-title">Collaboration</h2>
          <p className="value-description">Fostering strong partnerships with entrepreneurs, nurturing a supportive community for mutual growth.</p>
        </div>
      </div>
      <div className="value-box">
        <img src={ExcellenceIcon} alt="Excellence" className="value-icon" />
        <div className="value-content">
          <h2 className="value-title">Excellence</h2>
          <p className="value-description">Striving for superior quality and continuous improvement in delivering services and achieving results.</p>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Startup2launchMission;

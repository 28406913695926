import React from 'react';
import './StudentBanner.scss';
import StudentBannerImg from '../../../../Assets/StudentProfile/Students_header.png';

const StudentBanner = () => {
    return (
        <div className='studentbanner-section'>
            <div className='studentbanner'>
                <div className='studentbanner-content'>
                    <h2>Students</h2>
                </div>
                <div className='studentbanner-images'>
                    <img src={StudentBannerImg} alt='StudentBannerImg' />
                </div>
            </div>
        </div>
    )
}

export default StudentBanner;

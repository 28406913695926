import React from 'react';
import Abha from '../../../Assets/AboutUs/Team cards/Abha.png';
import Allwin from '../../../Assets/AboutUs/Team cards/Allwin.png';
import Arpita from '../../../Assets/AboutUs/Team cards/Arpita.png';
import Asiba from '../../../Assets/AboutUs/Team cards/Asiba.png';
import Batrick from '../../../Assets/AboutUs/Team cards/Batrick.png';
import Gopal from '../../../Assets/AboutUs/Team cards/Gopal.png';
import Jayanth from '../../../Assets/AboutUs/Team cards/Jayanth.png';
import Venket from '../../../Assets/AboutUs/Team cards/Group 108.png';
import Harish from '../../../Assets/AboutUs/Team cards/Group 109.png';
import Krishna from '../../../Assets/AboutUs/Team cards/Group 110.png';
import Prathyusha from '../../../Assets/AboutUs/Team cards/Prathyusha.png';
import Jerin from '../../../Assets/AboutUs/Team cards/Jerin.png';
import Mahika from '../../../Assets/AboutUs/Team cards/Mahika.png';
import Guillermo from '../../../Assets/AboutUs/Team cards/Memo.png';
import Nidhi from '../../../Assets/AboutUs/Team cards/Nidhi.png';
import Phani from '../../../Assets/AboutUs/Team cards/Phani.png';
import Shaji from '../../../Assets/AboutUs/Team cards/Shaji.png';
import Tania from '../../../Assets/AboutUs/Team cards/Tania.png';
import "./Startup2launchTeam.scss";

const teamMembers = [
    { name: 'Abha', position: 'Head of Product', img: Abha },
    { name: 'Allwin', position: 'Software Engineer', img: Allwin },
    { name: 'Arpita', position: 'Head of Operations', img: Arpita },
    { name: 'Asiba', position: 'Marketing Analyst', img: Asiba },
    { name: 'Batrick', position: 'Software Engineer', img: Batrick },
    { name: 'Guillermo', position: 'Sr. UX, Experience Analyst', img: Guillermo },
    { name: 'Jayanth', position: 'Software Engineer', img: Jayanth },
    { name: 'Jerin', position: 'Sr. Software Engineer', img: Jerin },
    { name: 'Krishna', position: 'Head of Technology', img: Krishna },
    { name: 'Mahika', position: 'Head of Pre-Sales, Coach', img: Mahika },
    { name: 'Nicky', position: 'Technical Advisor, Coach', img: Harish },
    { name: 'Nidhi', position: 'UX, Experience Analyst', img: Nidhi },
    { name: 'Prathyusha', position: 'Technical Mgr. Front End', img: Prathyusha },
    { name: 'Shaji', position: 'Lead, Software Engineer', img: Shaji },
    { name: 'Tania', position: 'Lead, Marketing', img: Tania },
    { name: 'Venket', position: 'Technical Advisor, Coach', img: Venket }
];

const executiveAdvisors = [
    { name: 'Phani', position: 'Executive Advisor, Coach', img: Phani },
    { name: 'Gopal', position: 'Executive Advisor, Coach', img: Gopal }
];

const TeamMember = ({ name, position, img }) => (
    <div className='team-member'>
        <div className='img-sec'>
            <img src={img} alt={`${name} Image`} />
        </div>
        <div className='details'>
            <div className='name'>{name}</div>
            <div className='position'>{position}</div>
        </div>
    </div>
);

const Startup2launchTeam = () => {
    return (
        <div id='ourTeam' className='main-sec-TM'>
            <div className='headerSec'>
                <div className='left'>
                    <span>Our Team </span>
                </div>
                <div className='right'>
                    Collaboration is at our core, with a highly skilled team of 13 experts spanning the USA,
                    Mexico, and India. We possess the diverse knowledge and experience necessary to bring your vision to fruition.
                </div>
            </div>

            <div className='team-sec'>
                {teamMembers.map(member => (
                    <TeamMember
                        key={member.name}
                        name={member.name}
                        position={member.position}
                        img={member.img}
                    />
                ))}
            </div>

            <h2>Executive Advisors</h2>
            <div className='team-sec'>
                {executiveAdvisors.map(advisor => (
                    <TeamMember
                        key={advisor.name}
                        name={advisor.name}
                        position={advisor.position}
                        img={advisor.img}
                    />
                ))}
            </div>
        </div>
    );
};

export default Startup2launchTeam;

import React, { useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import FaqImage from '../../../Assets/Academy/faq.png';

const FAQ = () => {
    const [activeIndexBenefit, setactiveIndexBenefit] = useState(null);
    const [currentImage, setCurrentImage] = useState(FaqImage);

    const [benefitQuestions] = useState([
        {
            label: "How long is the workshop?",
            content: "This is an 8-week workshop with new lessons each week.",
            highlightedContent: '',
        },
        {
            label: "Is it free to join?",
            content: "No, the workshop is completely free for middle and high school students.",
            highlightedContent: '',
        },
        {
            label: "Can I access the platform on my phone?",
            content: "Yes, the platform is mobile-friendly and can be used on any device.",
            highlightedContent: '',
        },
    ]);

    const toggleAccordion = (index) => {
        setactiveIndexBenefit(activeIndexBenefit === index ? null : index);
    };

    return (
        <div className='benefitS2L-section mt-5'>
            <div className='m-5'>
                <div className="section-heading ">
                    <h2 className='text-center'>Frequently Asked Questions</h2>
                    <div className='s2l-paragraph'>These are the answers to some commonly asked questions: </div>
                </div>
                <div className="s2l-content-section col-12 mt-2 ">
                    <div className='col-5 flex align-items-center justify-content-center img-section'>
                        <img src={currentImage} alt='FaqImage' className='' />
                    </div>
                    <div className='mt-6 faq-sub-headings col-7 details-section'>
                        <Accordion>
                            {benefitQuestions.map((faq, index) => (
                                <AccordionTab
                                    key={index}
                                    header={
                                        <div
                                            className={`accordion-faq-header ${activeIndexBenefit === index ? 'active-tab' : ''}`}
                                            onClick={() => toggleAccordion(index)}
                                        >
                                            {faq.label}
                                            <FontAwesomeIcon
                                                icon={activeIndexBenefit === index ? faMinus : faPlus}
                                                className="accordion-icon"
                                            />
                                        </div>
                                    }
                                    contentClassName={activeIndexBenefit === index ? 'active-content' : ''}
                                >
                                    <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                    <p>{faq.content}</p>

                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div>
                </div>

            </div>
        </div >
    );
};

export default FAQ;

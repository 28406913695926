import React, { useEffect, useState } from 'react'
import './OurProjects.scss';
import Bannerimage from '../../../Assets/Home Page/Looking-to-expand-your-team.png';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

const OurProjects = () => {

    const [, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleRedirect = () => {
        navigate('/recruitment');
    }

    return (
        <>
            {window.innerWidth <= 887 ? (
                <>
                    <div className="section-heading m-5">
                    <h2 className='border-bottom-3'>Looking to Expand your Team?</h2>
                    </div>
                    <div className="banner-sections">
                        <section className="layout py-0">

                            <div className='banner-content'>
                                <section >
                                    <span>Transform your startup vision into reality with the power of right talent.</span>
                                </section>

                                <div className='banner-images text-center mobile-Img'>
                                    <img src={Bannerimage} alt='Bannerimage' />
                                </div>
                            </div>

                            {/* <div className='banner-button'>
                                <Button onClick={handleRedirect}>Explore Recruitment Options</Button >
                            </div> */}

                        </section>
                    </div>

                </>
            ) : (
                <>
                    <div className="section-heading m-5">
                        <h2 className='border-bottom-3'>Looking to Expand your Team?</h2>
                    </div>
                    <div className="banner-sections">
                        <section className="layout py-0">

                            <div className='banner-content'>
                                <section >
                                    <ul>
                                        <li>Navigating Growth while finding the right talent can be <span style={{ color: "#8DAF61" }}>challenging.</span> </li>
                                        <li>Our Recruitment Experts understand your <span style={{ color: "#8DAF61" }}>unique</span> needs and will ensure a seamless fit.</li>
                                        <li>Empowering you to accelerate the <span style={{ color: "#8DAF61" }}>growth</span> of your business.</li>
                                    </ul>
                                </section>

                                {/* <div className='banner-button'>
                                    <Button onClick={handleRedirect}>Explore Recruitment Options</Button >
                                </div> */}
                            </div>

                            <div className='banner-images'>
                                <img src={Bannerimage} alt='Bannerimage' />
                            </div>

                        </section>
                    </div >
                </>
            )}
        </>
    )
}

export default OurProjects
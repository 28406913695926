import React, { useState } from "react";
import "./Features.scss";
import ReviewTopics from "../../../Assets/Academy/Review-Topics.jpg";
import StudyMaterial from "../../../Assets/Academy/Study-Material.jpg";
import CourseNotes from "../../../Assets/Academy/Course-Notes.jpg";
import DigitalTwin from "../../../Assets/Academy/Digital-Twin.jpg";
import ContactMentor from "../../../Assets/Academy/Contact-Mentor.jpg";

const FeatureCard = ({ image, initialTitle, hoverTitle, overlayColor, border, description, hoverImage, hoverBorder }) => {
    const [hovered, setHovered] = useState(false);

    return (
        <>

            <div className="feature-card"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                {hovered ?
                    (
                        <div className="hover-items"  style={{ border: border }}>
                            <img src={hoverImage} alt={initialTitle} style={{ border: hoverBorder }} />
                            <div className="hover-heading">{initialTitle}</div>
                            <div className="hover-description">{description}</div>
                        </div>
                    ) : (
                        <>
                            <img src={image} alt={initialTitle} />
                            <div>
                                <div className="overlay" style={{ backgroundColor: overlayColor, border: border }}></div>
                                <div className="feature-text">{hovered ? hoverTitle : initialTitle}</div>
                            </div>
                        </>
                    )
                }
            </div>

        </>
    );
};

const Features = () => {
    return (
        <div className="features-section">
            <h2 className="features-title">Features</h2>
            <p className="features-subtitle">
                These are some of the main features that you will find on the platform
            </p>


            <div className="features-row">
                <FeatureCard
                    image={ReviewTopics}
                    hoverImage={ReviewTopics}
                    initialTitle="Review Topics"
                    hoverTitle="Explore Review Topics"
                    overlayColor="#EF506499"
                    border="6px solid #EF5064"
                    hoverBorder="3px solid #EF5064"
                    description="Access and review course content at your convenience."
                />
                <FeatureCard
                    image={StudyMaterial}
                    hoverImage={StudyMaterial}
                    initialTitle="Study Materials"
                    hoverTitle="Browse Study Materials"
                    overlayColor="#8DAF6199"
                    border="6px solid #8DAF61"
                    hoverBorder="3px solid #8DAF61"
                    description="Case studies, real-life examples, Articles, and Videos."
                />
                <FeatureCard
                    image={CourseNotes}
                    hoverImage={CourseNotes}
                    initialTitle="Course Notes"
                    hoverTitle="Check Course Notes"
                    overlayColor="#35275099"
                    border="6px solid #F9AB60"
                    hoverBorder="3px solid #F9AB60"
                    description="Track your thoughts, insights, and assignments."
                />
            </div>


            <div className="features-row center-row">
                <FeatureCard
                    image={DigitalTwin}
                    hoverImage={DigitalTwin}
                    initialTitle="Digital Twin"
                    hoverTitle="Explore Digital Twin"
                    overlayColor="#4072D299"
                    border="6px solid #4072D2"
                    hoverBorder="3px solid #4072D2"
                    description="Ask your Digital Twin for immediate assistance."
                />
                <FeatureCard
                    image={ContactMentor}
                    hoverImage={ContactMentor}
                    initialTitle="Contact Your Mentor"
                    hoverTitle="Reach Out to Mentor"
                    overlayColor="#35275099"
                    border="6px solid #352750"
                    hoverBorder="3px solid #352750"
                    description="Ask your mentor for guidance and assistance."
                />
            </div>
        </div>
    );
};

export default Features;

import React from "react";
import Header from "../../Shared-Components/WorkshopHeader/Header";
import WorkshopGuideBanner from "./WorkshopGuideBanner/WorkshopGuideBanner";
import StudyGuideSteps from "./StudyGuideSteps/StudyGuideSteps";
import Features from "./Features/Features";
import FAQ from "./FAQ/FAQ";
import JoinAcademy from "../Academy/Academy-Home/Join-Academy/JoinAcademy";
import Footer from "../Home/Footer/Footer";

const WorkshopGuide = () => {
    return (
        <>
            <Header />
            <WorkshopGuideBanner />
            <StudyGuideSteps />
            <Features />
            <FAQ />
            <JoinAcademy showProgram={false} />
            <Footer />
        </>
    )
}

export default WorkshopGuide;
import React, { useState, useEffect } from 'react'; 
import "./Studentdetails.scss";
import MaleImg from '../../../../Assets/StudentProfile/Boy_Image_illu.png';
import FemaleImg from '../../../../Assets/StudentProfile/Girl_Image_illu.png';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';


const StudentDetails = () => {

    const studentsData = [
        {
            "name": "Smeeth Talasila",
            "grade": "10th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "male"
        },
        {
            "name": "Gayathri Ranganadham",
            "grade": "9th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "female"
        },
        {
            "name": "Adithi Rajkumar",
            "grade": "10th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "female"
        },
        {
            "name": "Atharv Mannava",
            "grade": "8th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "male"
        },
        {
            "name": "Jagruth Balabhadrappagari",
            "grade": "7th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "male"
        },
        {
            "name": "Akshar Bethina",
            "grade": "11th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "male"
        },
        {
            "name": "Surya Vadlamudi",
            "grade": "10th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "male"
        },
        {
            "name": "Kaitlyn Smith",
            "grade": "11th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "female"
        },
        {
            "name": "Emilia Bryte",
            "grade": "8th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "female"
        },
        {
            "name": "Bhargav Harihar",
            "grade": "12th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "male"
        },
        {
            "name": "Shravya Kasturi",
            "grade": "11th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "female"
        },
        {
            "name": "Isha Muppala",
            "grade": "10th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "female"
        },
        {
            "name": "Reeva Joshi",
            "grade": "9th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "female"
        },
        {
            "name": "Arihaan Guntupalli",
            "grade": "8th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "male"
        },
        {
            "name": "Anisha Dey",
            "grade": "6th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "female"
        },
        {
            "name": "Yashas Boddu",
            "grade": "9th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "male"
        },
        {
            "name": "Sushank Sukesh",
            "grade": "8th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "male"
        },
        {
            "name": "Vibhav Thamma",
            "grade": "9th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "male"
        },
        {
            "name": "Sweeya Talasila",
            "grade": "7th grade",
            "workshopDate": "June 8th, 2024",
            "workshopName": "S2L Academy Workshop for Students",
            "location": "Plano Hoggard Library, Geneology Program Room",
            "gender": "female",
        },
        {
            "name": "Ahaan Nandagawali",
            "grade": "8th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "male",
        },
        {
            "name": "Gagana Latchupatula",
            "grade": "10th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
        {
            "name": "Varnika Bhatter",
            "grade": "10th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
        {
            "name": "Kavita Leve",
            "grade": "6th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
        {
            "name": "Satvik Leve",
            "grade": "6th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "male",
        },
        {
            "name": "Sirsh Parachuri",
            "grade": "8th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "male",
        },
        {
            "name": "Shruthi Mohan",
            "grade": "9th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
        {
            "name": "Piper Jenkins",
            "grade": "7th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
        {
            "name": "Lily Romito",
            "grade": "7th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
        {
            "name": "Abhay Kasuganti",
            "grade": "11th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "male",
        },
        {
            "name": "Aditi Kasuganti",
            "grade": "9th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
        {
            "name": "Anushka Sharma",
            "grade": "12th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
        {
            "name": "Zak Khalid",
            "grade": "9th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "male",
        },
        {
            "name": "Anvitha Rayala",
            "grade": "10th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
        {
            "name": "Rian Gajera",
            "grade": "10th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "male",
        },
        {
            "name": "Aarav Potdar",
            "grade": "11th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "male",
        },
        {
            "name": "Swathi Ande",
            "grade": "7th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
        {
            "name": "Akshita Ande",
            "grade": "9th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
        {
            "name": "Aarush Sharma",
            "grade": "7th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "male",
        },
        {
            "name": "Elizabeth Nammrro",
            "grade": "7th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
        {
            "name": "Asritha Buchireddy",
            "grade": "7th grade",
            "workshopDate": "July 27th, 2024",
            "workshopName": "Master Enterpreneurship, Excel High School and Ace College Admissions",
            "location": "Frisco Library",
            "gender": "female",
        },
    ];

    const [students, setStudents] = useState(studentsData);
    const [filteredStudents, setFilteredStudents] = useState(studentsData);
    const [sortOrder, setSortOrder] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const sortOptions = [
        { label: 'A-Z', value: 'az' },
        { label: 'Z-A', value: 'za' }
    ];

    useEffect(() => {
        // Filter students based on search term
        const filtered = studentsData.filter(student =>
            student.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredStudents(filtered);
    }, [searchTerm]);

    useEffect(() => {
        // Sort filtered students based on sort order
        let sorted = [...filteredStudents];
        if (sortOrder === 'az') {
            sorted.sort((a, b) => a.name.localeCompare(b.name));
        } else if (sortOrder === 'za') {
            sorted.sort((a, b) => b.name.localeCompare(a.name));
        }
        setFilteredStudents(sorted);
    }, [sortOrder, filteredStudents]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSortChange = (e) => {
        setSortOrder(e.value);
    };


    return (
        <>
            <div className='studentdetails-section'>
                <div className='search-sort-bar'>
                    <div>
                        <InputText
                            value={searchTerm}
                            onChange={handleSearch}
                            placeholder='Search by name...'
                            className="p-inputtext"
                            style={{ height: '50px'}}
                        />
                    </div>
                    <div className='pl-4'>
                        <Dropdown
                            value={sortOrder}
                            options={sortOptions}
                            onChange={handleSortChange}
                            placeholder="Sort by"
                            className="p-dropdown"
                            showClear
                            style={{ width: '200px' , height: '50px'}}
                        />
                    </div>

                </div>

                <div className='studentdetails-sec mt-5'>
                    {filteredStudents.map((student, index) => (
                        <div className='studentdetails-member' key={index}>
                            <div className='img-sec'>
                                <img src={student.gender === 'male' ? MaleImg : FemaleImg} alt={student.gender} />
                            </div>
                            <div className='details'>
                                <div className='name'>{student.name}</div>
                                <div className='grade'>{student.grade} Student</div>
                                <a href={`/Certificate?name=${encodeURIComponent(student.name)}&workshop=${encodeURIComponent(student.workshopName)}&location=${encodeURIComponent(student.location)}`} className="certificate-button">
                                    View Certificate
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default StudentDetails;
import { Button } from 'primereact/button';
import React from 'react';
import bannerImg from "../../../../Assets/Academy/BannerImage.png";
import "./AcademyBanner.scss";
import { useNavigate } from 'react-router-dom';

const AcademyBanner = () => {

    const navigate = useNavigate();

    const scrollToSection = (sectionId) => {
        navigate(window.location.pathname);

        setTimeout(() => {
            const section = document.getElementById(sectionId);

            const headerHeight = window.innerWidth <= 1120 ? 100 : 250;

            if (section) {
                const sectionPosition = section.getBoundingClientRect().top + window.scrollY - headerHeight;
                window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
            }
        }, 0);
    };

    return (
        <>
            <div className='s2l-banner-section mx-2'>
                <div className='banner-content'>
                    <h2>Welcome <span className="startup-text">Creative Teens</span><br /></h2>
                    <p>A unique entrepreneurship program empowering middle and high school students.</p>
                    <p>S2L Academy helps middle and high school students turn ideas into startups with hands-on learning.
                        <b> Think Differently:</b> Use First Principles Thinking.
                        <b> Manage Your Time:</b> Learn how to stay organized.
                        <b> Educational Tips:</b> Get advice for better grades.
                        <b> Start-Up Basics:</b> Understand how to launch and run a business. Unlock your potential with S2L Academy.
                    </p>

                    <Button className='purple-bg' onClick={() => scrollToSection('academy-plan')}>Learn More</Button>

                    {/* <div className="flex gap-2">
                        <Button className='button-style-square-line'><a href='/workshopGuide' rel="noopener noreferrer">Academy Workshop Guide</a></Button>
                    </div> */}
                </div>


                <div className='banner-image'>
                    <img src={bannerImg} alt='People working around a table' />
                </div>
            </div>

        </>
    )
}

export default AcademyBanner
import React from 'react';
import './AboutusBanner.scss';
import bannerImgDesktop from '../../../Assets/AboutUs/People-working-table-header-about-us.png';
import Aboutusbannerimage from '../../../Assets/AboutUs/Header_About_Us_Page.png';
const StartupLaunchBanner = () => {
    return (
        <div className='about-us-banner'>
            <div className='banner-content'>
                <h2>Welcome to <span className="startup-text">Startup2Launch</span><br/>where ideas become extraordinary businesses.</h2>
            </div>
            <div className='banner-image'>
                <img src={Aboutusbannerimage} alt='People working around a table' />
            </div>
        </div>
    );
};

export default StartupLaunchBanner;

import React, { useState } from 'react';
import './Workshops.scss';
import { ScrollTop } from 'primereact/scrolltop';
import Header from '../../Home/Header/Header';
import Footer from '../../Home/Footer/Footer';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';

const Workshops = ({ workshops }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // Filter workshops based on the search term
    const filteredWorkshops = workshops.filter(workshop =>
        workshop.url.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <ScrollTop />
            <Header />
            <div className='workshops-container'>
                <h2>Workshops</h2>
                <div className='workshops-grid grid'>
                    <div className='workshops-left-section col-12 md:col-9'>
                        <div>
                            {filteredWorkshops.map((workshop) => (
                                <div key={workshop.id} className='workshop'>
                                    <div className='w-name'>{workshop.workshopName}<span className="w-tag" ><Tag value={workshop.status}
                                        severity={workshop.statusCode === 'C' ? 'success' : 'info'} /></span></div>
                                    <div className='w-date-time flex'>
                                        <div className='w-date'>{workshop.date}</div>
                                        <div className='w-readtime'>{workshop.read_time}</div>
                                    </div>
                                    <div className='w-description'>
                                        {workshop.description}
                                    </div>
                                    <div className='rm-link'>
                                        <Link
                                            to={
                                                workshop.statusCode === 'C'
                                                    ? `/workshop/${encodeURIComponent(workshop.url.replace(/\s+/g, '-').toLowerCase())}`
                                                    : `/upcomingevents/${encodeURIComponent(workshop.url.replace(/\s+/g, '-').toLowerCase())}`
                                            }
                                            className="read-more-link"
                                        >
                                            {workshop.statusCode === 'C' ? `Read More` : `Register Now`}
                                        </Link> &gt;
                                    </div>
                                </div>
                            ))}
                            {filteredWorkshops.length === 0 && (
                                <div className="no-results">
                                    No workshops found.
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='workshops-right-section col-12 md:col-3'>
                        <div className='search'>
                            <div className='search-title'>Search workshop</div>
                            <div className="input-with-icon pt-2" style={{ width: "100%" }}>
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText value={searchTerm} onChange={handleSearch} placeholder="Search..." style={{ width: "110%" }} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Workshops;

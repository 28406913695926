import React, { useState } from "react";
import logoOpen from "../../Assets/Home Page/S2L-Logo.svg";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from 'react-router-dom';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleLogin = () => {
        navigate('/login');
    }

    const handleSignup = () => {
        navigate('/signup');
    }

    return (
        <header className="s2l-header">
            <nav className="navigation">
                <div className="logo">
                    <a href="/"><img src={logoOpen} alt="" /></a>
                </div>

                <div
                    className={`menu-toggle ${showMenu ? "active" : ""}`}
                    onClick={toggleMenu}>
                    <div className={`bar ${showMenu ? "cross" : ""}`} ></div>
                    <div className={`bar ${showMenu ? "cross" : ""}`} ></div>
                    <div className={`bar ${showMenu ? "cross" : ""}`} ></div>
                </div>

                <div className={`menu ${showMenu ? "show-menu" : ""}`}>
                    <ul>
                        <li className={location.pathname === '/newclientform' ? 'active-menu-item' : ''}>
                            <a href="/newclientform">Contact Us</a>
                        </li>

                        <li>
                            <Button className="button-style-square-line" onClick={handleLogin}>Login</Button>
                        </li>

                        <li>
                            <Button onClick={handleSignup}>Create an Account</Button>
                        </li>
                    </ul>
                </div>

                {window.innerWidth <= 767 && (
                    <div className={`menu ${showMenu ? "show-menu" : ""}`}>
                        <ul>
                            <li className={location.pathname === '/newclientform' ? 'active-menu-item' : ''}>
                                <a href="/newclientform">Contact Us</a>
                            </li>

                            <li>
                                <Button className="button-style-primary-square-line" onClick={handleLogin}>Login</Button>
                            </li>

                            <li>
                                <Button onClick={handleSignup}>Create an Account</Button>
                            </li>
                        </ul>
                    </div>
                )}
            </nav >
        </header >
    );
};

export default Header;

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../LandingPage/Home/Home';
import Plans from '../LandingPage/Plans/Plans';
import Services from '../LandingPage/Services/Services';
import NewClientForm from '../LandingPage/NewClient-Form/NewClient-Form';
import AboutUs from '../LandingPage/AboutUs/AboutUs';
import Recruitment from '../LandingPage/Recruitment/Recruitment';
import FAQ from '../LandingPage/Faq/Faq';
import Academy from '../LandingPage/Academy/Academy-Home/Academy';
import Investor from '../LandingPage/Investor/Investor';
import OurWork from '../LandingPage/Our-Work/OurWork';
import AryaNowProject from '../LandingPage/Our-Work/Our-Project-Section/AryaNowProject';
import LaulamaProject from '../LandingPage/Our-Work/Our-Project-Section/LaulamaProject';
import AutismProject from '../LandingPage/Our-Work/Our-Project-Section/AutismProject';
import PaymentOption from '../LandingPage/Our-Work/OurDemoProject/PaymentOption';
import ChatbotOption from '../LandingPage/Our-Work/OurDemoProject/ChatbotOption';
import CommunicationModule from '../LandingPage/Our-Work/OurDemoProject/CommunicationModule';
import VideoAnalysis from '../LandingPage/Our-Work/OurDemoProject/ImageVideoAnalysis';
import Appointment from '../LandingPage/Our-Work/OurDemoProject/Appointment';
import StudentProfile from '../LandingPage/Academy/Academy-StudentsProfile/Home/Home';
import Workshops from '../LandingPage/Academy/Academy-Workshops/Workshops';
import WorkshopDetails from '../LandingPage/Academy/Academy-Workshops/WorkshopDetails';
import UpcomingEvents from '../LandingPage/Academy/Academy-UpcomingEvents/UpcomingEvents';
import UpcomingEventsDetails from '../LandingPage/Academy/Academy-UpcomingEvents/UpcomingEventsDetails';
import WSData from '../LandingPage/Academy/Academy-Workshops/WorkshopsData';
import Speakers from '../LandingPage/Academy/Academy-Speakers/Speakers';
import SpeakerDetails from '../LandingPage/Academy/Academy-Speakers/SpeakerDetails';
import UEData from '../LandingPage/Academy/Academy-UpcomingEvents/UpcomingEventsData';
import AcademyInternship from '../LandingPage/Academy/Academy-StudentsProfile/Academy-Internship/AcademyInternship';
import Dashboard from '../Module/Dashboard/Dashboard';
import Certificate from '../LandingPage/Academy/Certificate/Certificate.jsx';
import StudentDetails from '../LandingPage/Academy/Academy-StudentsProfile/StudentDetails/Studentdetails.jsx';
import WorkshopGuide from '../LandingPage/WorshopGuide/WorkshopGuide';
import LoginPage from '../Module/Authentication/Login/LoginPage';
import Signup from '../Module/Authentication/Signup/Signup';
import ConfirmationPage from '../Module/Authentication/Signup/ConfirmationPage/ConfirmationPage';
import ForgotPasswordPage from '../Module/Authentication/Login/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from '../Module/Authentication/Login/ResetPasswordPage/ResetPasswordPage';
import InfoPage from '../Module/Dashboard/InfoPage/InfoPage';
import Admin from '../Module/Admin/Admin';

function Navigation() {

    const [workshopsData, setWorkshopsData] = useState([]);
    const [UpcomingEventsData, setUpcomingEventsData] = useState([]);

    useEffect(() => {
        // Scroll to the top of the page on route change
        const onRouteChange = () => {
            window.scrollTo(0, 0);
        };

        // Listen for route changes
        window.addEventListener('hashchange', onRouteChange);

        // Clean up event listener
        return () => {
            window.removeEventListener('hashchange', onRouteChange);
        };
    }, []);


    useEffect(() => {
        // Load workshops data
        setWorkshopsData(WSData);
    }, []);

    useEffect(() => {
        // Load upcoming events data
        setUpcomingEventsData(UEData);
    }, []);

    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />

                    <Route path='/aboutus' element={<AboutUs />} />

                    <Route path='/plans' element={<Plans />} />

                    <Route path='/services' element={<Services />} />

                    {/* <Route path='/recruitment' element={<Recruitment />} /> */}

                    <Route path='/s2lAcademy' element={<Academy />} />

                    <Route path='/ourWork' element={<OurWork />} />

                    <Route path='/investor/AryaNow' element={<AryaNowProject />} />
                    <Route path='/investor/Laulama' element={<LaulamaProject />} />
                    <Route path='/investor/Autism' element={<AutismProject />} />

                    {/* demo project path */}

                    <Route path='/ourWork/payment' element={<PaymentOption />} />

                    <Route path='/ourWork/chatbot' element={<ChatbotOption />} />

                    <Route path='/ourWork/CommunicationModule' element={<CommunicationModule />} />

                    <Route path='/ourWork/Image&VideoAnalysis' element={<VideoAnalysis />} />

                    <Route path='/ourWork/Appointment' element={<Appointment />} />

                    <Route path='/Faq' element={<FAQ />} />

                    <Route path='/newclientform' element={<NewClientForm />} />

                    <Route path='/investor' element={<Investor />} />

                    <Route path='/studentprofile' element={<StudentProfile />} />
                    <Route path="/" element={<StudentDetails />} />
                    <Route path="/certificate" element={<Certificate />} />


                    <Route path="/workshops" element={<Workshops workshops={workshopsData} />} />

                    <Route path="/workshop/:name" element={<WorkshopDetails workshops={workshopsData} />} />

                    <Route path="/speakers" element={<Speakers speakers={workshopsData} />} />

                    <Route path="/speaker/:speakerName" element={<SpeakerDetails workshops={workshopsData} />} />

                    <Route path='/upcomingevents' element={<UpcomingEvents UpcomingEvents={UpcomingEventsData} />} />

                    <Route path="/upcomingevents/:name" element={<UpcomingEventsDetails UpcomingEvents={UpcomingEventsData} />} />

                    <Route path='/8-weekcourse' element={<AcademyInternship />} />

                    <Route path='/workshopGuide' element={<WorkshopGuide />} />

                    <Route path='/dashboard' element={<Dashboard />} />

                    <Route path='/login' element={<LoginPage />} />

                    <Route path='/signup' element={<Signup />} />

                    <Route path='/signup/confirmcode' element={<ConfirmationPage />} />

                    <Route path='/forgot-password' element={<ForgotPasswordPage />} />

                    <Route path='/resetPassword' element={<ResetPasswordPage />} />

                    <Route path='/additionalInfo' element={<InfoPage />} />

                    <Route path='/adminDashboard' element={<Admin />} />

                </Routes>
            </Router>
        </div>
    );
}

export default Navigation;

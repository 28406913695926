import React, { useState, useEffect } from 'react';
import "./HowItWorks.scss";
import { Galleria } from 'primereact/galleria';
import IdeationConsultation from "../../../Assets/Home Page/HowItWork/Ideation-Consultation.png";
import ExpertExecution from "../../../Assets/Home Page/HowItWork/Expert-Execution.png";
import MarketLaunchPreparations from "../../../Assets/Home Page/HowItWork/Market-Launch-Preparations.png";
import OnGoingSupport from "../../../Assets/Home Page/HowItWork/Ongoing-Support.png";
import TailoredStrategyDevelopment from "../../../Assets/Home Page/HowItWork/Tailored-Strategy-Develpment.png";

const HowItWorks = () => {
    const initialList = [
        {
            title: "Ideation Consultation",
            subTitle: "Share Your Vision, Define Your Goals",
            description: "Begin with a personal consultation to align your vision with achievable objectives. ",
            image: IdeationConsultation
        },
        {
            title: "Tailored Strategy Development",
            subTitle: "Crafting Your Custom Path to Success",
            description: "We develop a unique strategy that caters specifically to your startup's needs.",
            image: TailoredStrategyDevelopment
        },
        {
            title: 'Expert Execution',
            subTitle: "Transforming Ideas into Tangible Results",
            description: "Our team of experts diligently works to turn your concept into a reality.",
            image: ExpertExecution
        },
        {
            title: 'Market Launch Preparation',
            subTitle: "Positioning Your Business for Market Entry",
            description: "Get ready for the market with our comprehensive launch and marketing plan.",
            image: MarketLaunchPreparations
        },
        {
            title: 'Ongoing Support',
            subTitle: "Guiding You Beyond the Launch",
            description: "Even after launch, we provide continuous support for sustained growth.",
            image: OnGoingSupport
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % initialList.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [initialList]);

    const itemTemplate = (item) => {
        return (
            <div className='mobile-view'>
                <div className='section-col-1-contents text-center'>
                    <img src={item.image} alt="" />
                    <div className='work-section'>
                        <h2 className='border-bottom-3' >
                            <div style={{ color: "#000" }}>
                                {item.title}
                            </div>
                        </h2>
                        <span>{item.subTitle}</span>
                        <p>{item.description}</p>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="section-heading m-5 mb-0">
                <h2 className='border-bottom-3'> How it works?</h2>
                <p>
                    At Startup2launch (S2L), we kickstart your journey with an in-depth Idea Consultation,
                    followed by Tailored Strategy Development to align with your vision. Our team then moves to Expert Execution,
                    ensuring your idea materializes efficiently. We prepare you for Market Launch,
                    and our commitment extends into Ongoing Support, fostering sustained growth and success.
                </p>
            </div>
            <div className="m-4 mt-0">
                <div className="howItWorks-section">

                    {window.innerWidth <= 1008 ? (
                        <Galleria
                            value={initialList}
                            changeItemOnIndicatorHover={false}
                            showThumbnails={false}
                            showIndicators
                            item={itemTemplate}
                            autoPlay={true}
                            circular={true}
                            transitionInterval={5000}
                        />
                    ) : (
                        < div className="col flex howItWorks-section-content">
                            <div className="col-7 section-col-1 col-md-6 border-right-3">
                                <div className='section-col-1-contents'>
                                    <img src={initialList[currentIndex].image} alt="" />
                                    <div>
                                        <h2>
                                            {initialList[currentIndex].title}
                                        </h2>
                                        <span>
                                            {initialList[currentIndex].subTitle}
                                        </span>
                                        <hr className='border-bottom-2' />
                                        <p >{initialList[currentIndex].description}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-5 col-md-6 list-section">
                                <ul>
                                    {initialList.map((item, index) => (
                                        <li key={index} className={index === currentIndex ? 'highlighted-text' : 'heading'} >
                                            <h2 className={index === currentIndex ? 'border' : 'heading'}>
                                                {item.title}
                                            </h2>
                                            <p className='paragraph'>{item.description}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div >
        </>
    );
};

export default HowItWorks;
import React, { useState } from 'react';
import '../Benefit-from-S2L/BenefitfromS2L.scss';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Benefitimage from '../../../Assets/Home Page/Benefitimage.png';

import futureimage from '../../../Assets/Home Page/BenefitfromS2L/Future Innovators.png';
import entrepreneursimage from '../../../Assets/Home Page/BenefitfromS2L/Emerging Entrepreneurs.png';
import digitalimage from '../../../Assets/Home Page/BenefitfromS2L/Digitally Transforming Businesses.png';
import hackhathonimage from '../../../Assets/Home Page/BenefitfromS2L/Hackathon Heroes.png';
import educationimage from '../../../Assets/Home Page/BenefitfromS2L/Education Innovators.png';
import sustainabilityimage from '../../../Assets/Home Page/BenefitfromS2L/Sustainability Startups.png';
import creativeimage from '../../../Assets/Home Page/BenefitfromS2L/Creative Thinkers.png'


const BenefitfromS2L = () => {
    const [activeIndexBenefit, setactiveIndexBenefit] = useState(null);
    const [currentImage, setCurrentImage] = useState(Benefitimage);

    const [benefitQuestions] = useState([
        {
            label: 'Future Innovators (High School Students)',
            content: 'For the bold young minds eager to leave their mark, S2L offers early mentorship and resources to turn burgeoning ideas into potential startups, fostering an entrepreneurial mindset from the get-go.',
            highlightedContent: '',
            image: futureimage
        },
        {
            label: 'Emerging Entrepreneurs',
            content: "If you're on the brink of entrepreneurship, teeming with ideas and seeking the right direction, S2L is your launchpad. We provide the validation, resources, and network you need to transition from aspirant to founder.",
            highlightedContent: '',
            image: entrepreneursimage
        },
        {
            label: 'Digitally Transforming Businesses',
            content: 'For existing businesses poised for reinvention, S2L is your digital architect. We help you navigate the complexities of digital transformation, ensuring your business stays relevant, innovative, and competitive.',
            highlightedContent: '',
            image: digitalimage
        },
        {
            label: 'Hackathon Heroes',
            content: 'Winning a hackathon is just the beginning. S2L bridges the gap between prototype and product, offering the support hackathon winners need to successfully bring their innovative solutions to market.',
            highlightedContent: '',
            image: hackhathonimage
        },
        {
            label: 'Education Innovators',
            content: 'For educators and institutions aiming to cultivate entrepreneurial skills among students, S2L collaborates to integrate practical, real-world business challenges into academic environments, preparing students for future success.',
            highlightedContent: '',
            image: educationimage
        },
        {
            label: 'Sustainability Startups',
            content: 'In the era of conscious capitalism, if your startup idea champions sustainability and environmental stewardship, S2L provides specialized support to amplify your impact, connecting you with like-minded investors and mentors.',
            highlightedContent: '',
            image: sustainabilityimage
        },
        {
            label: 'Creative Thinkers',
            content: 'Artists, designers, and creators turning innovative concepts into business opportunities. S2L offers creative professionals the tools and strategies to monetize their creativity effectively and sustainably.',
            highlightedContent: '',
            image: creativeimage
        },
    ]);

    const toggleAccordion = (index) => {
        setactiveIndexBenefit(activeIndexBenefit === index ? null : index);
        setCurrentImage(benefitQuestions[index].image);
    };

    return (
        <div className='benefitS2L-section mt-5'>
            <div className='m-5'>
                <div className="section-heading ">
                    <h2 className='border-bottom-3'>Who Can Benefit from S2L?</h2>
                    <div className='s2l-paragraph'>S2L is designed for a wide array of visionaries, each at different stages of their entrepreneurial journey, looking to make a substantial impact in their fields. </div>
                </div>
                <div className="s2l-content-section col-12 mt-7 ">
                    <div className='col-5 flex align-items-center justify-content-center img-section'>
                        <img src={currentImage} alt='Benefitimage' className='' />
                    </div>
                    <div className='mt-6 faq-sub-headings col-7 details-section'>
                        <Accordion>
                            {benefitQuestions.map((faq, index) => (
                                <AccordionTab
                                    key={index}
                                    header={
                                        <div
                                            className={`accordion-faq-header ${activeIndexBenefit === index ? 'active-tab' : ''}`}
                                            onClick={() => toggleAccordion(index)}
                                        >
                                            {faq.label}
                                            <FontAwesomeIcon
                                                icon={activeIndexBenefit === index ? faMinus : faPlus}
                                                className="accordion-icon"
                                            />
                                        </div>
                                    }
                                    contentClassName={activeIndexBenefit === index ? 'active-content' : ''}
                                >
                                    <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                    <p>{faq.content}</p>

                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div>
                </div>
                <div className='flex align-items-center justify-content-center s2l-para mt-4 mb-4'>Join us to transform your entrepreneurial aspirations into tangible achievements.</div>
            </div>
        </div >
    );
};

export default BenefitfromS2L;

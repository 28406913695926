import React from "react";
import Step1 from "../../../Assets/Academy/sign-up-form.jpg";
import Step2 from "../../../Assets/Academy/Enter-your-info.jpg";
import Step3 from "../../../Assets/Academy/Start-learn.jpg";
import "./StudyGuideSteps.scss"

const StudyGuideSteps = () => {
    return (

        <>
            <div className="academy-plan-section">
                <div className="academy-plan-section-header">
                    <h2 className="text-center">
                        Start using S2L Workshop Study Guide Platform
                    </h2>
                </div>

                <div className="sg-section">
                    <div className="steps step1">
                        <div className="steps-details">
                            <div className="text-center">
                                <div className="steps-image">
                                    <img src={Step1} alt="Step 1" />
                                    <span className="step-number">1</span>
                                </div>
                                <div className="details">
                                    <h3>Create An Account</h3>
                                    <p>Sign up with your email, create a password and verify your email.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="steps step2">
                        <div className="steps-details">
                            <div className="steps-image">
                                <img src={Step2} alt="Step 2" />
                                <span className="step-number">2</span>
                            </div>
                            <div className="details">
                                <h3>Enter Your Information</h3>
                                <p>Fill in your profile details and areas of interest.</p>
                            </div>
                        </div>
                    </div>
                    <div className="steps step3">
                        <div className="steps-details">
                            <div className="steps-image">
                                <img src={Step3} alt="Step 3" />
                                <span className="step-number">3</span>
                            </div>
                            <div className="details">
                                <h3>Start Using the Platform</h3>
                                <p>Access weekly content, take notes, and track your progress.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StudyGuideSteps;